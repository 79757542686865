<script>
  import { DAYS_OF_WEEK } from '../../lib/dateUtils';
  import { products } from '../../stores/products';

  export let product;
  export let dayDateMap;

  function formatDate(date) {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  }

  $: productDetails = $products.find(p => p.id === product.produceName);
</script>

<div class="border-t pt-4">
  <h4 class="font-medium text-gray-800">
    {productDetails?.name || product.produceName}
    {#if product.variety}
      <span class="text-sm text-gray-600 ml-2">({product.variety})</span>
    {/if}
  </h4>
  
  <div class="mt-2 space-y-2 text-sm">
    <div class="flex justify-between items-center">
      <span>Price:</span>
      <div class="text-right">
        <span class="font-medium">
          {product.price} RON/{product.unit}
        </span>
        {#if productDetails?.boxSize}
          <div class="text-xs text-gray-500">
            Box size: {productDetails.boxSize} {product.unit}s/box
          </div>
        {/if}
      </div>
    </div>
    <p class="flex justify-between">
      <span>Total Quantity:</span>
      <span class="font-medium">
        {product.totalQuantity} Boxes
      </span>
    </p>
    
    <div class="mt-2">
      <p class="font-medium mb-1">Daily Quantities:</p>
      {#each DAYS_OF_WEEK as day}
        <div class="flex justify-between text-sm">
          <span class="text-gray-700">
            {day}
            {#if dayDateMap?.[day]}
              <span class="text-gray-500 ml-1">
                ({formatDate(dayDateMap[day])})
              </span>
            {/if}
          </span>
          <span>
            {product.dailyQuantities[day]} Boxes
          </span>
        </div>
      {/each}
    </div>
  </div>
</div>
