<script>
  import { OFFER_STATUS } from '../../stores/offers';

  export let status;

  function getStatusClass(status) {
    switch (status) {
      case OFFER_STATUS.APPROVED:
        return 'bg-green-100 text-green-800';
      case OFFER_STATUS.REJECTED:
        return 'bg-red-100 text-red-800';
      case OFFER_STATUS.NEEDS_REVISION:
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  }
</script>

<span class={`px-3 py-1 rounded-full text-sm font-medium ${getStatusClass(status)}`}>
  {status.charAt(0).toUpperCase() + status.slice(1)}
</span>
