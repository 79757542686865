import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { showSuccess, showError } from '../lib/toast';
import { refreshSession } from '../lib/session/sessionService';
import { validateForm } from '../lib/validation';
import { profileSchema } from '../lib/validation/schemas/profile';

export async function updateProfile(userId, profileData) {
  if (!userId) {
    showError('Invalid user ID');
    return false;
  }

  try {
    // Validate profile data
    const { isValid, errors } = await validateForm(profileSchema, profileData);
    if (!isValid) {
      showError(Object.values(errors)[0]);
      return false;
    }

    const userRef = doc(db, 'users', userId);
    
    // Prepare update data
    const updateData = {
      ...profileData,
      updatedAt: new Date().toISOString()
    };

    // Remove any undefined or null values
    Object.keys(updateData).forEach(key => {
      if (updateData[key] === undefined || updateData[key] === null) {
        delete updateData[key];
      }
    });

    await updateDoc(userRef, updateData);
    
    // Refresh session to update user data
    await refreshSession();
    
    showSuccess('Profile updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating profile:', error);
    showError(error.message || 'Failed to update profile');
    return false;
  }
}