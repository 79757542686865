<script>
  import { session } from '../../lib/session';
  import { OFFER_STATUS } from '../../stores/offers';
  import ProductDetails from './ProductDetails.svelte';
  import DeliveryAllocations from './DeliveryAllocations.svelte';

  export let offer;
  export let onStatusUpdate;
  export let onDelete;
  export let loading = false;
  export let canModify = false;

  let feedbackText = '';

  $: isAdmin = $session.user?.role === 'admin';
</script>

<div class="mt-4 space-y-6">
  {#each offer.products as product}
    <ProductDetails {product} dayDateMap={offer.dayDateMap} />
  {/each}

  {#if offer.description}
    <div class="border-t pt-4">
      <p class="text-sm font-medium text-gray-700">Additional Notes:</p>
      <p class="mt-1 text-gray-600">{offer.description}</p>
    </div>
  {/if}

  {#if offer.feedback}
    <div class="p-3 bg-yellow-50 rounded-lg">
      <p class="text-sm font-medium text-yellow-800">Admin Feedback:</p>
      <p class="text-sm text-yellow-700 mt-1">{offer.feedback}</p>
    </div>
  {/if}

  {#if offer.deliveryAllocations}
    <DeliveryAllocations 
      allocations={offer.deliveryAllocations}
      products={offer.products}
      dayDateMap={offer.dayDateMap}
    />
  {/if}

  {#if isAdmin && offer.status === OFFER_STATUS.SUBMITTED}
    <div class="border-t pt-4">
      <label class="block text-sm font-medium text-gray-700 mb-2">
        Feedback for Producer
      </label>
      <textarea
        bind:value={feedbackText}
        rows="3"
        class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
        placeholder="Enter feedback for the producer..."
      ></textarea>

      <div class="flex justify-end gap-3 mt-4">
        <button
          on:click={() => onStatusUpdate(offer.id, OFFER_STATUS.NEEDS_REVISION, feedbackText)}
          disabled={loading}
          class="px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 disabled:opacity-50"
        >
          Request Changes
        </button>
        <button
          on:click={() => onStatusUpdate(offer.id, OFFER_STATUS.REJECTED, feedbackText)}
          disabled={loading}
          class="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
        >
          Reject
        </button>
        <button
          on:click={() => onStatusUpdate(offer.id, OFFER_STATUS.APPROVED, feedbackText)}
          disabled={loading}
          class="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50"
        >
          Approve
        </button>
      </div>
    </div>
  {:else if canModify}
    <div class="flex justify-end gap-3">
      <button
        on:click={() => onDelete(offer.id)}
        disabled={loading}
        class="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
      >
        {loading ? 'Deleting...' : 'Delete'}
      </button>
      <button
        on:click={() => onStatusUpdate(offer.id)}
        disabled={loading}
        class="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
      >
        Edit
      </button>
    </div>
  {/if}
</div>
