import * as yup from 'yup';

export const profileSchema = yup.object().shape({
  companyName: yup
    .string()
    .required('Company name is required'),
  vatNumber: yup
    .string()
    .required('VAT number is required'),
  address: yup.object().shape({
    street: yup.string().required('Street is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    postalCode: yup.string().required('Postal code is required'),
    lat: yup.number().required('Latitude is required'),
    lng: yup.number().required('Longitude is required')
  }),
  certifications: yup.object().shape({
    globalGap: yup.object().shape({
      number: yup.string(),
      validUntil: yup.string(),
      status: yup.string().oneOf(['valid', 'pending', 'expired'])
    }),
    grasp: yup.object().shape({
      number: yup.string(),
      validUntil: yup.string(),
      status: yup.string().oneOf(['valid', 'pending', 'expired'])
    }),
    eco: yup.object().shape({
      number: yup.string(),
      validUntil: yup.string(),
      status: yup.string().oneOf(['valid', 'pending', 'expired'])
    })
  })
});