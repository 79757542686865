import Cookies from 'js-cookie';

const COOKIE_OPTIONS = {
  expires: 1, // 1 day
  secure: true,
  sameSite: 'strict',
  path: '/'
};

export function setCookie(name, value) {
  Cookies.set(name, JSON.stringify(value), COOKIE_OPTIONS);
}

export function getCookie(name) {
  const cookie = Cookies.get(name);
  if (cookie) {
    try {
      return JSON.parse(cookie);
    } catch (error) {
      console.error('Failed to parse cookie:', error);
      return null;
    }
  }
  return null;
}

export function removeCookie(name) {
  Cookies.remove(name, { path: '/' });
}

export function clearAllCookies() {
  // Get all cookies
  const cookies = Cookies.get();
  
  // Remove each cookie
  Object.keys(cookies).forEach(cookie => {
    Cookies.remove(cookie, { path: '/' });
  });
}