import { derived, writable } from 'svelte/store';
import { auth, db } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { showSuccess, showError } from '../lib/toast';
import { signInFirebase, signOutFirebase } from '../lib/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { clearSession } from '../lib/session';
import { navigate } from '../lib/router';

// Create auth store
export const authStore = {
  user: writable(null),
  loading: writable(true),
  error: writable(null),
};

// Derived store for authentication status
export const isAuthenticated = derived(
  authStore.user,
  ($user) => $user !== null
);

// Initialize auth state listener
onAuthStateChanged(auth, async (user) => {
  authStore.loading.set(true);
  if (user) {
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        authStore.user.set({
          ...userData,
          id: user.uid,
          email: user.email,
        });
        authStore.error.set(null);
      } else {
        throw new Error('User document not found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      authStore.user.set(null);
      authStore.error.set(error.message);
    } finally {
      authStore.loading.set(false);
    }
  } else {
    authStore.user.set(null);
    authStore.loading.set(false);
    authStore.error.set(null);
  }
});

// Sign in with email and password
export async function signIn(email, password) {
  try {
    authStore.loading.set(true);
    const user = await signInFirebase(email, password);
    authStore.user.set(user);
    authStore.error.set(null);
    return user;
  } catch (error) {
    console.error('Sign in error:', error);
    authStore.error.set(error.message);
    showError(error.message);
    throw error;
  } finally {
    authStore.loading.set(false);
  }
}

// Sign out
export async function signOut() {
  try {
    authStore.user.set(null);
    authStore.loading.set(false);
    authStore.error.set(null);

    await signOutFirebase();

    // Clear session and cookies, then navigate to login
    clearSession();
  } catch (error) {
    console.error('Sign out error:', error);
    authStore.error.set(error.message);
    showError(error.message);
    throw error;
  }
}
