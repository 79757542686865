import { collection, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { session } from './sessionStore';
import { showError } from '../toast';
import { navigate } from '../router';
import { clearAllCookies } from '../cookies';

export async function initializeSession() {
  try {
    return new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          try {
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              const token = await user.getIdToken();
              session.set({
                token,
                user: {
                  id: user.uid,
                  email: user.email,
                  ...userData,
                },
                expiresAt: new Date(Date.now() + 3600 * 1000).toISOString(),
              });

              // Redirect to appropriate page based on role
              if (
                userData.role === 'admin' &&
                window.location.pathname !== '/admin'
              ) {
                navigate('/admin');
              } else if (
                userData.role !== 'admin' &&
                window.location.pathname === '/admin'
              ) {
                navigate('/');
              }
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
            showError('Failed to load user profile');
          }
        } else {
          clearSession();
          if (!window.location.pathname.includes('/login')) {
            navigate('/login');
          }
        }
        unsubscribe();
        resolve();
      });
    });
  } catch (error) {
    console.error('Session initialization error:', error);
    showError('Failed to initialize session');
  }
}

export async function refreshSession() {
  try {
    const user = auth.currentUser;
    if (!user) {
      clearSession();
      throw new Error('No authenticated user');
    }

    const [token, userDoc] = await Promise.all([
      user.getIdToken(true),
      getDoc(doc(db, 'users', user.uid)),
    ]);

    if (!userDoc.exists()) {
      throw new Error('User document not found');
    }

    const userData = userDoc.data();
    session.set({
      token,
      user: {
        id: user.uid,
        email: user.email,
        ...userData,
      },
      expiresAt: new Date(Date.now() + 3600 * 1000).toISOString(),
    });

    return true;
  } catch (error) {
    console.error('Session refresh error:', error);
    showError('Failed to refresh session');
    return false;
  }
}

export function clearSession() {
  // Clear all cookies
  clearAllCookies();

  // Clear session store
  session.clear();

  // Force navigation to login
  navigate('/login');
}
