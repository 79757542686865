import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import { auth } from './firebase';
import { showSuccess, showError } from './toast';
import { navigate } from './router';

export async function signInFirebase(email, password) {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    console.error('Sign in error:', error);
    throw error;
  }
}

export async function signOutFirebase() {
  try {
    // Sign out from Firebase
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
}
