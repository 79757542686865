<script>
  import { onMount } from 'svelte';
  import { session } from '../lib/session';
  import { offers, OFFER_STATUS, deleteOffer, updateOffer } from '../stores/offers';
  import { showSuccess, showError, showConfirm } from '../lib/toast';
  import { requireAuth } from '../lib/session/sessionGuard';
  import MultiProduceForm from '../components/MultiProduceForm.svelte';
  import OffersList from '../components/offers/OffersList.svelte';
  import OfferFilters from '../components/offers/OfferFilters.svelte';

  let editingOffer = null;
  let loading = false;
  let searchTerm = '';
  let statusFilter = 'all';
  let sortBy = 'newest';

  onMount(() => {
    // Check if user has access
    if (!requireAuth(['admin', 'producer'])) {
      return;
    }
  });

  async function handleDelete(offerId) {
    if (loading) return;

    const confirmed = await showConfirm('Are you sure you want to delete this offer?');
    if (!confirmed) return;

    loading = true;
    try {
      const success = await deleteOffer(offerId);
      if (!success) {
        throw new Error('Failed to delete offer');
      }
      editingOffer = null;
      showSuccess('Offer deleted successfully');
    } catch (error) {
      console.error('Error deleting offer:', error);
      showError(error.message || 'Failed to delete offer');
    } finally {
      loading = false;
    }
  }

  async function handleSubmit(offerData) {
    if (!editingOffer?.id || loading) return;

    loading = true;
    try {
      const success = await updateOffer(editingOffer.id, {
        ...offerData,
        status: OFFER_STATUS.SUBMITTED,
        lastModified: new Date().toISOString()
      });

      if (!success) {
        throw new Error('Failed to update offer');
      }

      showSuccess('Offer updated successfully');
      editingOffer = null;
    } catch (error) {
      console.error('Error updating offer:', error);
      showError(error.message || 'Failed to update offer');
    } finally {
      loading = false;
    }
  }

  function startEdit(offer) {
    if (offer.status !== OFFER_STATUS.SUBMITTED) {
      showError('Only submitted offers can be edited');
      return;
    }
    
    // Only allow editing own offers for producers
    if ($session.user.role === 'producer' && offer.producerId !== $session.user.id) {
      showError('You can only edit your own offers');
      return;
    }
    
    editingOffer = offer;
  }

  function cancelEdit() {
    editingOffer = null;
  }
</script>

<div class="container mx-auto px-4 py-8">
  {#if editingOffer}
    <div class="mb-8">
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-2xl font-semibold">Edit Offer</h2>
        <button
          on:click={cancelEdit}
          class="text-gray-600 hover:text-gray-800"
        >
          Cancel Edit
        </button>
      </div>
      <MultiProduceForm 
        existingOffer={editingOffer} 
        onSubmitCallback={handleSubmit}
      />
    </div>
  {:else if $session.user?.role === 'producer'}
    <div class="mb-8">
      <h2 class="text-2xl font-semibold mb-4">Submit New Offer</h2>
      <MultiProduceForm />
    </div>
  {/if}

  <div>
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-2xl font-semibold">
        {$session.user?.role === 'producer' ? 'My Offers' : 'All Offers'}
      </h2>
      <div class="text-sm text-gray-600">
        Total: {$offers.length}
      </div>
    </div>

    <OfferFilters
      bind:statusFilter
      bind:sortBy
      bind:searchTerm
    />

    <OffersList 
      offers={$offers}
      {statusFilter}
      {sortBy}
      {searchTerm}
      {loading}
      onStatusUpdate={(id, status, feedback) => {
        if ($session.user?.role !== 'admin') {
          showError('Only administrators can update offer status');
          return;
        }
        return updateOffer(id, { status, feedback });
      }}
      onDelete={(id) => {
        const offer = $offers.find(o => o.id === id);
        if (!offer) return;
        
        // Only allow deleting own offers for producers
        if ($session.user?.role === 'producer' && offer.producerId !== $session.user.id) {
          showError('You can only delete your own offers');
          return;
        }
        
        return handleDelete(id);
      }}
    />
  </div>
</div>
