<script>
  import { users } from '../../stores/users';
  import { DAYS_OF_WEEK } from '../../lib/dateUtils';

  export let allocations;
  export let products;
  export let dayDateMap;

  function getWarehouseDetails(warehouseRef) {
    if (!warehouseRef) return null;
    const [supermarketId, warehouseId] = warehouseRef.split(':');
    const supermarket = $users.find(s => s.id === supermarketId);
    return supermarket?.warehouses?.find(w => w.id === warehouseId);
  }

  function formatDate(date) {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  }
</script>

<div class="border-t pt-4">
  <h3 class="font-medium text-gray-900 mb-4">Delivery Allocations</h3>
  
  <div class="space-y-4">
    {#each DAYS_OF_WEEK as day}
      {#if allocations[day]}
        <div class="bg-gray-50 rounded-lg p-4">
          <h4 class="font-medium text-gray-800 mb-3">
            {day}
            {#if dayDateMap?.[day]}
              <span class="text-gray-500 ml-1">
                ({formatDate(dayDateMap[day])})
              </span>
            {/if}
          </h4>

          <div class="space-y-4">
            {#each products as product}
              {@const productAllocations = allocations[day].find(p => p.productId === product.produceName)}
              {#if productAllocations?.allocations?.length}
                <div class="border-t border-gray-200 pt-3 first:border-t-0 first:pt-0">
                  <h5 class="text-sm font-medium text-gray-700 mb-2">
                    {product.produceName}
                    {#if product.variety}
                      <span class="text-gray-500">({product.variety})</span>
                    {/if}
                  </h5>

                  <div class="space-y-2">
                    {#each productAllocations.allocations.filter(a => a.quantity > 0) as alloc}
                      {@const warehouse = getWarehouseDetails(alloc.warehouseId)}
                      {#if warehouse}
                        <div class="flex justify-between items-start text-sm">
                          <div>
                            <p class="text-gray-900">{warehouse.name}</p>
                            <p class="text-gray-600">
                              {warehouse.city}, {warehouse.state}
                            </p>
                            <p class="text-gray-500 text-xs mt-1">
                              Working hours: {warehouse.workingHours.start} - {warehouse.workingHours.end}
                            </p>
                          </div>
                          <div class="text-right">
                            <p class="font-medium text-gray-900">
                              {alloc.quantity} boxes
                            </p>
                            <p class="text-xs text-gray-500">
                              ({alloc.quantity * product.boxSize} {product.unit}s)
                            </p>
                          </div>
                        </div>
                      {/if}
                    {/each}
                  </div>
                </div>
              {/if}
            {/each}
          </div>
        </div>
      {/if}
    {/each}
  </div>
</div>
