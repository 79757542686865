<script>
  import { offers, OFFER_STATUS, addOffer, updateOffer } from '../stores/offers';
  import { currentUser } from '../stores/users';
  import { products } from '../stores/products';
  import { authorizations } from '../stores/authorizations';
  import { getCurrentWeek, getWeekDates, createDayDateMap, DAYS_OF_WEEK } from '../lib/dateUtils';
  import { offerSchema, validateForm } from '../lib/validation';
  import { showSuccess, showError } from '../lib/toast';
  import ProductEntry from './ProductEntry.svelte';
  import LoadingSpinner from '../lib/LoadingSpinner.svelte';

  export let existingOffer = undefined;
  export let onSubmitCallback = undefined;

  let editMode = false;
  let loading = false;
  let loadingProducts = true;
  let weekNumber = getCurrentWeek().next;
  let description = '';
  let offerProducts = [{
    id: crypto.randomUUID(),
    produceName: '',
    variety: '',
    price: '',
    dailyQuantities: Object.fromEntries(DAYS_OF_WEEK.map(day => [day, '']))
  }];

  let weekDates;
  let dayDateMap;
  let authorizedProductsList = [];
  let weekHasOffer;

  $: weekDates = getWeekDates(weekNumber, getCurrentWeek().year);
  $: dayDateMap = createDayDateMap(weekDates);

  $: {
    try {
      authorizedProductsList = $products.filter(product =>
        $authorizations.some(auth =>
          auth.userId === $currentUser?.id &&
          auth.productId === product.id
        )
      );
      loadingProducts = false;
    } catch (error) {
      console.error("Error fetching products:", error);
      authorizedProductsList = [];
      loadingProducts = false;
    }
  }

  $: weekHasOffer = $offers.some(o =>
    o.producerId === $currentUser?.id &&
    o.weekNumber === weekNumber &&
    (!editMode || o.id !== existingOffer?.id)
  );

  function addProduct() {
    offerProducts = [...offerProducts, {
      id: crypto.randomUUID(),
      produceName: '',
      variety: '',
      price: '',
      dailyQuantities: Object.fromEntries(DAYS_OF_WEEK.map(day => [day, '']))
    }];
  }

  function removeProduct(productId) {
    if (offerProducts.length > 1) {
      offerProducts = offerProducts.filter(p => p.id !== productId);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (loading) return;

    const unauthorized = offerProducts.some(p => 
      !authorizedProductsList.some(ap => ap.id === p.produceName)
    );
    if (unauthorized) {
      showError('You are not authorized to sell one or more of these products');
      return;
    }

    if (weekHasOffer && !editMode) {
      showError('You already have an offer for this week');
      return;
    }

    loading = true;
    try {
      const offerData = {
        producerId: $currentUser.id,
        weekNumber: Number(weekNumber),
        weekDates: weekDates.map(date => date.toISOString()),
        dayDateMap,
        products: offerProducts.map(p => ({
          ...p,
          price: Number(p.price),
          dailyQuantities: Object.fromEntries(
            Object.entries(p.dailyQuantities).map(([day, qty]) => [
              day,
              Number(qty) || 0
            ])
          )
        })),
        description
      };

      if (editMode && existingOffer) {
        await updateOffer(existingOffer.id, offerData);
        showSuccess('Offer updated successfully');
      } else {
        await addOffer(offerData);
        showSuccess('Offer submitted successfully');
      }
    } catch (error) {
      showError('Failed to save offer');
    } finally {
      loading = false;
    }
  }
</script>

<form on:submit={handleSubmit} class="bg-white p-6 rounded-lg shadow-md space-y-6">
  <div>
    <label class="block text-gray-700 text-sm font-bold mb-2">
      Week {weekNumber}
      <span class="font-normal text-gray-500">
        ({weekDates[0].toLocaleDateString()} - {weekDates[6].toLocaleDateString()})
      </span>
    </label>
    {#if weekHasOffer && !editMode}
      <p class="mt-1 text-red-600 text-sm">
        You already have an offer for this week. You can only submit one offer per week.
      </p>
    {/if}
  </div>

  <div class="space-y-8">
    {#each offerProducts as product (product.id)}
      <ProductEntry 
        {product} 
        {weekDates} 
        {authorizedProductsList} 
        {loadingProducts}
      />
    {/each}
  </div>

  <button
    type="button"
    on:click={addProduct}
    class="w-full py-2 px-4 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-green-500 hover:text-green-500 transition-colors"
  >
    + Add Another Product
  </button>

  <div>
    <label for="description" class="block text-gray-700 text-sm font-bold mb-2"> Offer Description </label> <textarea id="description" bind:value={description} placeholder="Optional: Add details about your offer" class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500" ></textarea>

  </div>
  <button type="submit" class="w-full bg-green-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-600 transition-colors" disabled={loading || weekHasOffer && !editMode}> {loading ? "Saving..." : editMode ? "Update Offer" : "Submit Offer"}
  </button> 
</form>