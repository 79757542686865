<script>
  import { navigate } from '../lib/router';
  import { session, clearSession } from '../lib/session';
  import { signOut } from '../stores/auth';
  import TimeDisplay from './TimeDisplay.svelte';
  import { showSuccess } from '../lib/toast';

  async function handleLogout() {
    await signOut();
    
    showSuccess('Logged out successfully');
    navigate('/login');
  }
</script>

<nav class="bg-green-600 text-white p-4">
  <div class="container mx-auto">
    <div class="flex justify-between items-center">
      <div class="flex items-center gap-6">
        <h1 class="text-2xl font-bold">FreshPlace</h1>
        {#if $session.user}
          <div class="flex gap-4">
            <button
              on:click={() => navigate('/')}
              class="hover:text-green-200 transition-colors"
            >
              Dashboard
            </button>
            <button
              on:click={() => navigate('/offers')}
              class="hover:text-green-200 transition-colors"
            >
              Offers
            </button>
            {#if $session.user.role === 'admin'}
              <button
                on:click={() => navigate('/admin')}
                class="hover:text-green-200 transition-colors"
              >
                Admin
              </button>
            {/if}
          </div>
        {/if}
      </div>
      <div class="flex items-center gap-6">
        <TimeDisplay />
        {#if $session.user}
          <div class="flex items-center gap-4">
            <span class="text-sm">Welcome, {$session.user.name}</span>
            {#if $session.user.role === 'producer'}
              <button
                on:click={() => navigate('/profile')}
                class="bg-green-700 hover:bg-green-800 px-4 py-2 rounded-lg transition-colors"
              >
                My Profile
              </button>
            {/if}
            <button
              on:click={handleLogout}
              class="bg-green-700 hover:bg-green-800 px-4 py-2 rounded-lg transition-colors"
            >
              Logout
            </button>
          </div>
        {/if}
      </div>
    </div>
  </div>
</nav>